import React, { useState, useEffect } from 'react';
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import './earthly.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import mission_first_page_slider from '../images/first_thum.png'
import mission_flame_retardants from '../images/mission_slider/Flame_retardants.jpg'
import mission_microbeads from '../images/mission_slider/Microbeads.jpg'
import mission_ingredient_transparency from '../images/mission_slider/Ingredient_transparency.jpg'
import mission_animal_testing_free from '../images/mission_slider/Animal_testing_free.jpg'
import mission_vegan from '../images/mission_slider/Vegan.jpg'
import mission_fairtrade from '../images/mission_slider/Fairtrade.jpg'
import mission_Non_GMO from '../images/mission_slider/Non-GMO.jpg'
import mission_Palm_oil_free from '../images/mission_slider/Palm_oil_free.jpg'





import commitment_img from '../images/commitment.png'
import commitment_to_you_img from '../images/commitment_to_you.png'


import first_thumb from '../images/first_thum.png'
import mission_flame_retardants_thumb from '../images/mission_slider/thumbnail/Flame_retardants.png'
import mission_microbeads_thumb from '../images/mission_slider/thumbnail/Microbeads.png'
import mission_ingredient_transparency_thumb from '../images/mission_slider/thumbnail/Ingredient_transparency.png'
import mission_animal_testing_free_thumb from '../images/mission_slider/thumbnail/Animal_testing_free.png'
import mission_vegan_thumb from '../images/mission_slider/thumbnail/Vegan.png'
import mission_fairtrade_thumb from '../images/mission_slider/thumbnail/Fairtrade.png'
import mission_Non_GMO_thumb from '../images/mission_slider/thumbnail/Non-GMO.png'
import mission_Palm_oil_free_thumb from '../images/mission_slider/thumbnail/Palm_oil_free.png'
import hero_img from '../images/earthly_banner.png';






import arrow_up from '../images/arrow-up.png'
import arrow_down from '../images/arrow-down.png'

const settingsThumbs = {
  className:"custom_slider_thumb",
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: false,
    focusOnSelect: true,
    height:"150px",
    // centerPadding: '10px',
    vertical:true,
    infinite: true,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          vertical:false,
        }
      },
    ]
  };

let settings = {
  dots: false,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  asNavFor: '.slider-nav',
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,

      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      }
    }
  ]
};
const slidesData = [
    {
      id: 1,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/toxic.svg",
    }, {
      id: 2,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/flameretardants.svg",
    }, {
      id: 3,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/microplastics.svg",
    }, {
      id: 4,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/transparency.svg",
    }, {
      id: 5,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/cruelty_free.svg",
    }, {
      id: 6,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/vegan.svg",
    },
    {
      id: 7,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/fair_trade.svg",
    },
    {
      id: 8,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/nongmo.svg",
    },
    {
      id: 9,
      img:"https://earthlydev.s3-us-west-2.amazonaws.com/icons/green/palmoil.svg",
    },
  ];

const Mission = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  useEffect(() => {
      setNav1(slider1);
      setNav2(slider2);

    });
  const previous = () =>{
      slider2.slickPrev();
  }
  const next = (event) =>{
    slider2.slickNext();
  }

  return (
  <Layout>
  <SEO title="Mission" image={hero_img} />
  <div style={{marginTop:"30px",marginBottom:"10px"}}>
  <div className="row">
  <div className="col-md-12 line ml-3">
  </div>
  </div>
  <div className="row" style={{marginTop:"40px"}}>
  <div className="col-md-12">
  </div>
  </div>
  <div className="row">
  <div className="col-md-10 col-sm-12">
      <h2 className="mission_header" style={{marginBottom:"20px",marginTop:"10px"}}>
      Know the Impact
    </h2>

    <p className=" mission_page_first_about" style={{marginTop:"20px"}}>
    The things humans make and consume has an impact on health, climate and the environment - 
    positive and negative! Not all the impact is obvious, the obvious ones being adverse weather
     and health consequences. The rest of the impact is being assessed by the tireless work of 
    research and non-profit organizations. Accessing this information conveniently and piecing 
    together the information is time consuming. 

    </p>
<p className="h6" style={{color:"#15B770"}}>
    Earthly is like the Consumer Reports for everyday products.
    It's gives you the impact that the products have on health, climate and environment.</p>
  </div>
  <div className="col-md-10 col-sm-12">
    <h2 className="mission_header" style={{marginBottom:"20px",marginTop:"10px"}}>
    Make brands align with your values</h2>
    <p className=" mission_page_first_about">Are the product ingredients toxic and best avoided? 
    Were animals harmed while developing these products? 
    Were the people involved equitably paid, or was it made by children who ought to have been in school? 
    Are any ingredients responsible for destruction of one of a kind habitats? Brands and the online sites you shop 
    on will seldom give you this information.
    </p>
    <p className="h6" style={{color:"#15B770"}}>
    Earthly overlays ratings on products as you shop on Amazon.com, taking into account all the attributes that make up 
    the product's impact on health, environment and society (as opposed to a single "Organic" or "fair trade" tag).
    </p>
  </div>
  <div className="col-md-10 col-sm-12">
    <h2 className="mission_header" style={{marginBottom:"20px",marginTop:"10px"}}>
    ...because corporations are just trying to meet your demand,
     at the cheap price you want, as fast as you want it</h2>
  </div>
  </div>
  <div className="row">
  <div className="col-md-10 col-sm-12">
          <p className=" mission_page_first_about">
            Armed with the data, you are empowered to buy better and support the causes you care about directly through your shopping.</p>
          <p className="h6" style={{color:"#15B770"}}>
            Earthly empowers consumers to swing the pendulum in favor of companies that are mindful of their impact to consumers, their homes and the planet. </p>

  </div>
  </div>
    <div className="row">
  <div className="col-md-10 col-sm-12">

  <a href="#sus"></a><h2 style={{marginTop:"50px"}}>Earthly's Sustainability Dimensions</h2>
          <p className=" mission_page_first_about">

          Earthly’s scoring and reporting on sustainability is based on 6 pillars: Harmful Chemicals, Waste Consciousness, Low Footprint, Protecting Animals, Social Responsibility and Habitat Preservation. Based on these, we either flag products that we’ve found to be less than desirable, or recommending products that have goodness in them.
          </p>
  </div>
  </div>

  <div className="row shadow mission_slider_section mb-5 bg-white" style={{marginTop:"30px",borderRadius:"15px"}}>

    <div className="col-md-11 mission" style={{padding:0,margin:0}}>

    <Slider {...settings} asNavFor={nav2}
            ref={slider => (setSlider1(slider))}>
      <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[0].img} className="slider_main_img" alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
              <h3 className="mt-2 mission_slider_title">Toxins and Chemicals</h3>
                <div className="slider_content">
                  <p>Products these days contain an alarming number of chemicals ingredients. This is mainly because 
                  chemicals can be manufactured far more cheaply and in bulk when compared to any natural equivalents. 
                  However, an alarming number of chemicals have been found to be harmful to humans and other lifeforms. 
                  These includes both confirmed and possible <a href="https://www.cancer.org/cancer/cancer-causes/general-info/known-and-probable-human-carcinogens.html">carcinogens</a>, <a href="https://www.hormone.org/your-health-and-hormones/endocrine-disrupting-chemicals-edcs">endocrine disruptors</a>, <a href="https://pubmed.ncbi.nlm.nih.gov/26106141/">immune disruptors</a>. 
                  These substances affect the health of adults, children and pets, as well as entire ecosystems when released into the environment </p>
                  <p>We take the highest precautions when evaluating products, making sure we inform you even if ingredients are suspected to have toxicity effects.</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      <div>
        <div className="row">
            <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[1].img} className="slider_main_img"  alt="First Slider img"/></div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-11 mission_slider_desc">
                    <h3 className="mt-2 mission_slider_title">Flame retardants</h3>
                      <div className="slider_content">
                        <p>Flame retardants are chemicals applied to items or surfaces to prevent or slow the spread of fire. 
                        They’re often added to furniture, home textiles, electronics, construction materials and a slew of other products including children's sleepwear! Testing has shown that flame retardants makes maybe a second-or-two difference in time to combustion. 
                        It’s not really meaningful when it comes to preventing the effects of a fire, however the <a href="https://www.cpsc.gov/Business--Manufacturing/Business-Education/Business-Guidance/Childrens-Sleepwear-Regulations">US</a> and <a href="https://laws-lois.justice.gc.ca/eng/regulations/SOR-2016-169/FullText.html">Canada</a> have laws that promote the use of flameretardants in children's sleepwear. 
                        From these products, the chemicals leak into the air or the environment and affect our health or the planet.</p>
                        <p>Flame retardants *may* help prevent the spread of fire, but they’re also associated with a variety of health problems, including:</p>
                        <ul>
                        <li>Immune system dysfunctions</li>
                        <li>Endocrine and thyroid dysfunction</li>
                        <li>Cancer</li>
                        <li>Reproductive toxicity</li>
                        <li>Neurologic dysfunction</li>
                        <li>Adverse effects on foetus development</li>
                        </ul>
                        <p>Children in particular are vulnerable to these chemicals, as their bodies are still developing. Additionally, these chemicals can prevail in the environment for a long time and affect us as well as the planet for years to come.</p>
                      </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[2].img} className="slider_main_img"  alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Microplastics</h3>
                  <div className="slider_content">
                    <p>Microplastics are microscopic specks of plastics that are seemingly everywhere. A class of microplastics called Microbeads are plastic particles smaller than 1mm, usually made from polyethylene and are an ingredient in countless home and body products. 
                    It is estimated that there could be upto 51 Trillion microplastic particles accumulated in the oceans and could account for 85% of all plastic pollution. They’re often added to cosmetics as scrubbing or exfoliating agents and often make their way into sewage as they’re flushed down the drain. 
                    Most particles are so small that they <a href="https://www.environment.gov.au/protection/waste/plastics-and-packaging/plastic-microbeads">can’t be filtered</a> out at wastewater treatment facilities and so they become a part of the water cycle.</p>

                    <p>While their effects on the environment and human health is still <a href="https://www.nature.com/articles/d41586-021-01143-3?utm_source=Nature+Briefing&utm_campaign=bd86911029-briefing-dy-20210505&utm_medium=email&utm_term=0_c9dfd39373-bd86911029-45816594">under investigation</a>, <a href="https://www.britannica.com/video/191900/report-microbead-pollution-effect-life">studies show</a> that small sea animals can starve to death after consuming microbeads (or other microplastics). More research into effects on human health is needed.</p>

                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="row">
            <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[3].img} className="slider_main_img"  alt="First Slider img"/></div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-11 mission_slider_desc">
                  <h3 className="mt-2 mission_slider_title">Ingredient transparency</h3>
                    <div className="slider_content">
                        <p>We all have the right to know every ingredient contained in the product we’re buying. However, the requirements for listing of all ingredients vary by region and by the type of product. Sometimes, ingredients that can affect a product’s sustainability and impact on human health do not legally have to be listed by the seller. This includes colors and fragrances which could cause allergic reactions, enzymes that could have animal origins, surfactants which vary in their chemical formulations.
                        </p>
                        <p>Ingredients form the basis of everything we do at Earthly. Hence, we flag any generic terms used in lieu of actual ingredients</p>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[4].img} className="slider_main_img"  alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Animal testing free</h3>
                  <div className="slider_content">
                    <p>More than 100 million animals are killed yearly during animal testing in the US alone. This includes mice, rats, frogs, dogs, cats, rabbits, hamsters, guinea pigs, monkeys, fish, and birds used for product testing or research. Not only are these tests quite unreliable and very wasteful – they also present one of the most prevailing examples of animal cruelty in today’s society.</p>
                    <p>Aside from the ethical and sustainability issues associated with this, ingredients that companies consider necessary to test on animals are usually potentially dangerous new chemicals – natural, long-tried ingredients do not need to be tested. Therefore, besides problems with animal testing itself, it’s also usually a red flag that the contents of the product aren’t good for you or the environment.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[5].img} className="slider_main_img"  alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Vegan</h3>
                  <div className="slider_content">
                    <p>Animal materials are significantly more environmentally-damaging, have a higher carbon footprint are require more resources to produce.</p>
                    <p>A choice as simple switching to plant-based options could reduce your carbon footprint by as much as 41.7%! It’s not just all about the environment or animal compassion – especially when it comes to foods, plant-based options help you live a healthier lifestyle.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[6].img} className="slider_main_img"  alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Fairtrade</h3>
                  <div className="slider_content">
                    <p>The Fairtrade certification is one of the easiest ways to track the ethical and economic impact of products. In a Fairtrade production cycle, employees are paid living wages and provided with safe working conditions they can further develop their skills in.
                    </p>
                    <p>In a world where workers, especially in developing countries, are treated unfairly and aren’t paid the minimum living wage, certifications like Fairtrade are increasingly important in ensuring no human rights were violated in production.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div>
        <div className="row">
          <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[7].img} className="slider_main_img"  alt="First Slider img"/></div>
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Non-GMO</h3>
                  <div className="slider_content">
                    <p>Genetic modification has many environmental and ethical implications. Some of these include negative impact on native wildlife, the potential for genes to mutate or end up in unexpected places, allergic reactions, antibiotic resistance, and more. adults, children and pets, as well as entire ecosystems when released into the environment </p>
                    <p>Because of these impacts, the presence of GMOs is one of the factors we monitor when assessing products on their sustainability.</p>
                  </div>
              </div>
            </div>
        </div>
      </div>
      </div>
      <div>
          <div className="row">
            <div className="col-md-4 d-flex align-items-center justify-content-center"><img src={slidesData[8].img} className="slider_main_img"  alt="First Slider img"/></div>
            <div className="col-md-8">
            <div className="row">
              <div className="col-md-11 mission_slider_desc">
                <h3 className="mt-2 mission_slider_title">Palm oil free</h3>
                  <div className="slider_content">
                    <p>Every hour, 300 football fields of precious remaining rainforest are lost in Malaysia and Indonesia. As a result, the critically endangered orangutan, the critically endangered Sumatran tiger and the critically endangered Sumatran elephant are being bulldozed out of existence. The biggest contributors to this is the <a href="https://palmoilinvestigations.org/about-palm-oil.html">cultivation of palm oil</a>, included in a wide variety of products today because of its low cost.</p>
                    <p>Palm oil is omnipresent, in our food, beauty and cleaning products. Identifying palm oil as an ingredient isn't straightforward as it may listed under a myriad of names (over 20 at last count!). Once we understand what purchasing products with palm oil ingredients does to these vulnerable species, it's easy to make the humane and ethical choice. </p>
                    <p>The <a href="http://www.rspo.org/about">RSPO (Roundtable for Sustainable Palm Oil)</a> was established in 2004 with the aim of promoting “certified sustainable” palm oil. Unfortunately, this initiative was too little, too late, with lax membership criteria and only two members thrown out for non-compliance to date. We at Earthly urge you to avoid any product containing Palm oil to send manufacturers a strong message.
                    We at Earthly urge you to avoid such products.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </Slider>
    </div>
    <div className="col-md-1">
    <div className="thumbnail-slider-wrap">
    <div>
          <button className="next_button" onClick={next}>
            <img src={arrow_up} alt="next" style={{marginBottom:0,marginTop:"18px"}} />
          </button>
      </div>
          <Slider
            {...settingsThumbs}
            asNavFor={nav1}
            ref={slider => (setSlider2(slider))}>

            {slidesData.map((slide) =>

              <div className="slick-slide" key={slide.id}>
                <img className="slick-slide-image" src={`${slide.img}`} />
              </div>

            )}

          </Slider>
          <div style={{marginTop:"25px"}}>
            <button className="next_button" onClick={previous}>
                <img src={arrow_down} alt="previous" />
            </button>
          </div>
        </div>
    </div>

  </div>
  <div className="row"></div>
  <div style={{marginTop:"40px",marginBottom:"40px"}}>
  <div className="row">
  <div className="col-md-6">
  <h2 style={{marginTop:"50px"}}>Our commitment to Evidence</h2>

  <p>At Earthly, we look past the marketing copy and base our rating of products on evidence and first principles. We also look further than information provided by companies themselves or influencers promoting their products, to ensure we always remain 100% objective.</p>
  </div>
  <div className="col-md-6 d-flex align-items-center justify-content-center">
  <img src={commitment_img} alt="commitment" />
  </div>
  </div>
  <div className="row" style={{marginTop:"30px",marginBottom:"30px"}}></div>
  <div className="row">
  </div>
  </div>
  </div>


  </Layout>
  )
}

export default Mission
